.customer_details_wrapper {
  /* width: 100%; */
  /* height: 36px; */
  padding: 11px 14px 10px 21px !important;
  /* background: #279b78; */
  background: #273a44;
  /* padding: 24px; */

  /* display: block; */
}
.list_item_bar {
  display: block;
}

.list_item_bar_txt {
  padding-right: 20px;
  font-size: 12px;
  margin: 0 !important;
}
.list_item_bar_cust {
  padding-right: 47px;

  font-weight: 400;
  font-size: 12px;
  /* line-height: 15px; */
  /*identical to box height */
  /* margin: 0 !important; */
  margin-bottom: 0px;
  padding-bottom: 0;
  text-transform: capitalize;

  color: #ffffff;
}
.list_item_bar_txt1 {
  padding-right: 47px;

  font-weight: 400;
  font-size: 12px;
  /* line-height: 15px; */
  /*identical to box height */
  /* margin: 0 !important; */
  margin-bottom: 0px;
  padding-bottom: 0;
  /* text-transform: capitalize; */
  text-decoration: none;

  color: #ffffff;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 991px) {
  .list_item_bar {
    display: flex;
  }
  .list_item_bar_txt1 {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1200px) {
}
