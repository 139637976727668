.modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 10px;
  color: #34495e !important;
}
.btn_group {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.btn_standard {
  margin-right: 20px;
}

.cancle_btn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: white !important;
  color: #717171 !important;
}

.addVehicleBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  /* background-image: linear-gradient(
    to right,
    #d31027 0%,
    #ea384d 51%,
    #d31027 100%
  ) !important; */
} /* Hader.module.css */
.search_main {
  width: 30%;
  position: relative;
  left: 20px;
}
.search {
  position: relative;
  border-radius: 4px;
  /* background-color: rgba(255, 255, 255, 0.15);
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  } */
  margin-right: 16px;
  margin-left: 0;
  padding-left: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  position: relative;
  right: 0;
}

.searchIcon {
  padding: 8px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputRoot {
  color: inherit;
  /* padding: 8px 8px 8px calc(1em + 32px); */
  transition: width 200ms ease-in-out;
  width: 100%;
  @media (min-width: 600px) {
    width: 20ch;
  }
}
.icon_user {
  position: relative;
  margin-right: 34px;
  align-items: center;
  display: flex;
  top: 3px;
  left: 5px;
}
.role_action {
  position: relative;
  right: 20px;
}
.louout_btn {
  display: flex;
  cursor: pointer;
}
.LogoutModal {
  width: 90%;
  margin: 0 auto;
}
.iconInfo {
  display: flex;
  justify-content: center;
  padding: 20px;
}

@media screen and (min-width: 768px) {
  .search {
    right: 115px;
  }
  .LogoutModal {
    width: 34%;
    margin: 0 auto;
  }
}
.checkinBtn {
  font-size: 10px !important;
  background-color: #0761e9 !important;
}
.checkoutBtn {
  font-size: 10px !important;
  background-color: #ff0000 !important;
}
