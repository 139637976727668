.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}
.navItmeWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.notificationUserWrapper {
  display: flex;
  justify-content: space-evenly;
  border: 1px solid red;
}
.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
}
.navbar_list {
  /* padding-left: 20px; */
  border-radius: 9px !important;
}
.leftsidebarmainLayout {
  flex-grow: 1;
  margin-top: 65px;
  height: calc(100vh - 65px);
  overflow: hidden;
  background: #f7f7fc;
  position: relative;
}

.mainlayout {
  flex-grow: 1;
  width: 100%;
  margin-top: 65px;
  height: calc(100vh - 100px);
  overflow: scroll;
  background: #f7f7fc;
  position: relative;
}

.navbar_list_active {
  padding-left: 20px;
  background-color: #23a67b !important;
  border-radius: 9px !important;
}
.sidebarnav {
  background-color: #1877f2;
}

.logo_box {
  position: absolute;
  left: 25%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}
.search_icon {
  float: left;
}
.box_header {
  background-color: blue;
  position: absolute;
  left: 200px;
}

/* today css */

.flag {
  /* position: absolute; */
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  background-color: #1a3235;
}
.grid_border {
  border-right: 1px solid #c7c7c7;
  height: 105vh;
}
.lineContainer {
  margin: 20px;
}
.subList:hover {
  background: #23a67b;
  color: #ffffff;
}
.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.googleMap {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 10px;
}
::placeholder {
  font-size: 12px;
}

._Institute {
  display: flex;
  justify-content: space-between;
}
._Institute_imageBox {
  display: flex;
  justify-content: left;
}
._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}
._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}
.styles._image_description {
  margin-left: 50px;
}
.upload_image {
  position: relative;
  border: none;
  padding: 5px;
  top: 80px;
  background-color: #1877f2;
  color: white;
}
.hader_txt {
  color: white;
}
.list_flat {
  display: flex;
}
.list_item_flat {
  display: flex !important;
  color: black !important;
}
.list_item {
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 40px;
  text-align: center !important;
}
.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
/* .navBarDivider{
    color: black !important;
  } */
/*  */

.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}

.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
}
.navbarmenu {
  margin-bottom: 10px !important;
}
.navbar_list {
  /* padding-left: 20px; */
  background-color: #ffffff !important;
  color: #9a9ab0 !important;
  border: 1.5px solid transparent;
  border-radius: 8px !important;
  width: 55px !important;
  height: 55px !important;
}

.navbar_list_active {
  /* padding-left: 10px; */
  background-color: #0761e9 !important;
  color: #ffffff !important;
  border: 1.5px solid transparent;
  border-radius: 8px !important;
  width: 55px !important;
  height: 55px !important;
}
.navbar_list_active img {
  filter: brightness(0) invert(1) !important;
}
.logo_box {
  position: absolute;
  left: 15%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}
.search_icon {
  float: left;
}
.box_header {
  background-color: blue;
  position: absolute;
  left: 200px;
}

/* today css */

.flag {
  /* position: absolute; */
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  padding: 20px;
  border-radius: 8px;
  background-color: #000;
}
.grid_border {
  border-right: 1px solid #c7c7c7;
  height: 105vh;
}
.lineContainer {
  margin: 20px;
}
/* .subList:hover {
  background: #23a67b;
  color: #ffffff;
} */
.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}
.googleMap {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 10px;
}
::placeholder {
  font-size: 12px;
}

._Institute {
  display: flex;
  justify-content: space-between;
}
._Institute_imageBox {
  display: flex;
  justify-content: left;
}
._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}
._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}
.styles._image_description {
  margin-left: 50px;
}
.upload_image {
  position: relative;
  border: none;
  padding: 5px;
  top: 80px;
  background-color: #1877f2;
  color: white;
}
.hader_txt {
  color: white;
}
.list_flat {
  display: flex;
}
.list_item_flat {
  display: flex !important;
  color: black !important;
}
.list_item {
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 40px;
  text-align: center !important;
}
.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
.sidebar_button {
  border-radius: 50% !important;
  width: 40px !important;
  height: 40px !important;
}
.css-1l0beii-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
  border-bottom: 1px solid #e1e1e1 !important;
}
.footer_root_main {
  /* position: relative; */
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 0;
}
.footer_root {
  /* position: absolute; */
  width: calc(100vw - 100px);
  position: fixed;
  bottom: 0;
  background: #fff;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
  .footer_root_main {
    width: 89%;
    padding-left: 89px;
  }
}
@media screen and (min-width: 850px) {
  .footer_root_main {
    width: 91%;
    padding-left: 89px;
  }
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1024px) {
  .footer_root_main {
    width: 92%;
    padding-left: 89px;
  }
}

@media screen and (min-width: 1200px) {
  .footer_root_main {
    width: 94%;
    padding-left: 89px;
  }
}
