.main_root {
  padding: 10px;
  padding-bottom: 100px;
}
.root_jobs {
  width: 100%;
  /* height: 300px; */
  /* height: 379px; */
  flex-shrink: 0;
  background-color: white;
  padding: 10px;
  margin-bottom: 25px;
}

.root_Revenue {
  width: 100%;
  padding: 10px;
  flex-shrink: 0;
  background-color: white;
  margin-bottom: 25px;
}
.SuccessRate {
  flex-shrink: 0;
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
}
.TopThreeVehicle {
  background-color: white;
  margin-bottom: 20px;
}
.job_headbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
  padding: 10px;
}
.txt_job {
  color: #273a44;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}
.jobStatusCard_progress {
  background-color: #e6f6fe;
  text-align: center;
  padding: 10px;
  margin-bottom: 14px;
  /* height: 193%; */
}
.jobStatusCard_progress_review {
  background-color: #edf7ee;
  text-align: center;
  padding: 10px;
  margin-bottom: 14px;
}
.jobStatusCard_ready {
  text-align: center;
  background: #edf7ee;
  padding: 10px;
  margin-bottom: 14px;
}
.jobStatusCard_Delivered {
  text-align: center;
  background: #fff5e6;
  padding: 10px;
  margin-bottom: 14px;
}
.jobStatusCard_pending {
  text-align: center;
  background: #ffe6e6;
  padding: 10px;
  margin-bottom: 14px;
}
.TotalVehicleHead {
  background: #fff;
  margin-bottom: 30px;
  padding: 20px;
  height: 100%;
}
.CustomerOnboardRoot {
  background: #fff;
  height: 260px;
}
.CustomerSatisfaction {
  background: #fff;
  height: 260px;
}
.TopServiceTaken {
  background: #fff;
  /* padding-bottom: 30px; */
  height: 260px;
}
.txt_count_progress {
  color: #03a9f4;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  margin: 0px;
}
.txt_count_redy {
  color: #4caf50;
  text-align: center;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  margin: 0px;
}
.jobStatus_txt {
  color: #273a44;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  margin: 0px;
}
.txt_count_delivered {
  color: #ff9800;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  margin: 0px;
}
.txt_count_Pending {
  color: #f00;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 10px;
  margin: 0px;
}
.count_revenue {
  color: #0761e9;
  /* Headline S/Desktop/Semibold */
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.28px;
  padding-left: 8px;
}
.progress_root {
  display: flex;
  justify-content: center;
}
.progress_title_txt {
  display: flex;
  justify-content: center;
}
.progress_title_txt_root {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  margin: 10px;
  font-size: 11px;
  line-height: 9px;
  position: relative;
  top: 4px;
}
.veiclename {
  display: flex;
}
.root_vec {
  padding: 14px 24px;
}
.txt_served_vec {
  color: #273a44;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  margin: 0px;
  padding: 10px;
}
.veicl_name_txt {
  margin-left: 14px;
  color: #273a44;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
  top: 6px;
}
.card_vs {
  border: 1px solid var(--neutral-600, #eaebf0);
  background-color: #fff;
  width: 100%;
  padding: 10px 15px;
}
.conunt_vs {
  color: #0761e9;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.28px;
  margin-left: 10px;
}
.card_title_vs {
  color: #273a44;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.card_count_vs_redy {
  color: #388e55;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}
.card_count_de {
  color: #ec0303;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}
.card_count_pe {
  color: #ea6f53;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.28px;
}
.card_count_per {
  color: var(--success-700, #2d8a39);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
}
.card_count_per_pending {
  color: #ea6f53;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
}
.progress_unbord {
  display: flex;
  justify-content: center;
  padding: 10px;
}
.progress_Customer_Satisfaction {
  display: flex;
  justify-content: center;
  padding: 22px;
}
.txt_job_cs {
  color: #273a44;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  position: relative;
  left: 2px;
  padding: 20px;
}
.job_headbar_cs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.service_time {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--neutral-600, #eaebf0);
  border-top: 1px solid var(--neutral-600, #eaebf0);
  align-items: center;
  padding: 15px;
  margin: 0px;
}
.service_time_root_img {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.service_time_wash_txt {
  margin-left: 20px;
  margin: 10px;
}
.circle_img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color: #f7f7fc;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.time_tab {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  background: var(--success-50, #f0faf0);
}
.count_service_time {
  margin: 0px;
  color: var(--success-700, #2d8a39);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.1px;
}
.time_tab_red {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  background: var(--danger-100, #fff2f0);
}
.count_service_time_red {
  margin: 0px;
  color: var(--danger-700, #e2341d);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.1px;
}
.service_time_wash_txt {
  color: #273a44;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
}
.service_time_taken {
  margin: 0px;
  color: var(--gray-50, #5f6d7e);
  /* Text XS/Medium */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.placeholder_payment {
  color: #273a44 !important;
  font-size: 12px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 700 !important;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
  .job_headbar {
    align-items: center;
    flex-direction: column;
  }
  .txt_job {
    font-size: 13px;
  }
  .txt_job_cs {
    font-size: 13px;
  }
}
@media screen and (min-width: 1150px) {
  .job_headbar {
    align-items: center;
    flex-direction: row;
  }
  .txt_job {
    font-size: 16px;
  }
  .txt_job_cs {
    font-size: 16px;
  }
}
@media screen and (min-width: 1200px) {
}
.progress_bar_root {
  height: 200px;
  width: 200px;
  display: flex;
  justify-content: center;
}
.job_counter_root {
}
