.inputForm {
  height: 10px !important;
}
.tabPanel {
  /* padding-bottom: 16px !important; */
}
.stepper_wrapper {
  margin: 0;
  padding: 19px 86px 20px 86px !important;
}
.bottom_section {
  /* position: fixed; */
  bottom: 0px;
  right: 0;
  width: 100%;
  /* background-color: #ffffff; */
  padding: 10px 10px;
  /* border-top: 1px solid #e1e1e1; */
  z-index: 98;
}
.selectedServicesTbMain {
  /* background-color: #fff;
  font-size: 12px; */
  color: #273a44;
  font-size: 12px;
  font-family: Inter !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  word-wrap: break-word;
}
.nextPreviousButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 24px;
  padding-bottom: 33px;
}
.backBtn {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */
  width: 53px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  text-transform: capitalize !important;

  color: #717171 !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  margin-right: 20px !important;
}
.saveBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;
  border-radius: 0px !important;
  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e9 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */
  text-transform: capitalize !important;
  color: #ffffff !important;
  /* margin-right: 24px !important; */
}
.hr {
  height: 2px;

  background: #f4f8fb;
}
.header {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;
  /* identical to box height */

  text-transform: capitalize;

  color: #2f333b;
}
.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #2f333b;
  margin-bottom: 8px;
}
.step_label {
  font-weight: 700 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  /* identical to box height */

  text-align: center !important;
  text-transform: uppercase !important;

  color: #2f333b !important;
}
.img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 5px 8px;
  background: #fff;
  /* cursor: pointer; */
}
/* .image_main_content {
  position: relative;
  height: 100px;
  right: 15px;
} */
.add_img_txt {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #0761e9;
}
.formControlLabel1 {
  background-color: #0761e9;
  padding-right: 15px;
  margin-bottom: 8px;
  color: #fff;
}
.formControlLabel {
  background-color: #fff;
  padding-right: 15px;
  margin-bottom: 8px;
}
/* .formControlLabel :checked{
   background-color:red !important;
} */

.main_container {
  display: flex;
  min-height: 59vh;
  border-bottom: 1px solid #e2e3e4;
}
.left_container {
  width: 30%;
  border-right: 1px solid #e1e1e1;
}
.right_container {
  width: 75%;
}
.selectedServicesTb {
  padding: 22px 16px !important;
  font-size: 12px !important;
}
.serviceHeading {
  /* background-color: #fff; */
  /* padding: 5px 0; */
  /* border-bottom: 1px solid #e1e1e1; */
}

.bottom_wrapper {
  /* width: 312px; */
  /* height: 80px; */

  /* background: #f0f1ff; */
  background: #ffffff;
  padding: 12px 23px 12px 20px;
  border-top: 1px solid #e1e1e1;
  margin-left: 0;
  /* border: 1px solid #f0f1ff; */
  /* position: fixed; */
  /* bottom: -24px; */
  /* z-index: 1; */

  width: 100%;
}
.view_all {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #0761e9;
}
.total_price {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 0;
  text-transform: capitalize;

  color: #2f333b;
}
.colorPickerDEsign {
  border-radius: 0px !important;
}
.InputRegistrationDesign {
  border-radius: 0px !important;
  background-color: #fff;
  padding: 2px 2px;
}

.customerDetailsSpacing {
  margin-top: 20px;
}
.vehiclesimagesAdd {
  border: 2px solid #e7e7e7;
  /* width: 792px; */
  height: 125px;
  display: flex;
  align-items: center;
  padding-left: 9px;
  background: #fff;
  margin-bottom: 10px !important;
}
.selected_services {
  /* width: 312px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: row; */
  /* height: 80px; */
  margin-left: 17px !important;
  margin-right: 17px !important;
  background: #f0f1ff;
  border: 1px solid #f0f1ff;
  margin: 0;
  margin-bottom: 4px;
  padding: 10px 14px 10px 6px;
}
.registratationbg {
  background: #f7f7fc;
  /* height: 99vh;
  overflow: auto; */
  /* padding-bottom: 100px; */
}
.left_img {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.service_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 7px;
  text-transform: capitalize;

  color: #2f333b;
}
.service_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 7px;
  color: #2f333b;
}
.etd_time {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #2f333b;
}
.complaint_name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  padding-right: 10px;
  /* identical to box height, or 158% */

  text-transform: capitalize;
  margin: 0;
  color: #3b3232;
}
.TechniciancianName {
  font-size: 12px !important;
}
.complaint_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e8f1ff;
  margin: 0;
  padding: 10px 8px 10px 11px;
  margin-bottom: 7px;
  max-width: fit-content;
  line-break: anywhere;
}
.servicestxt {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  margin-top: 22px;
  color: #2f333b;
}
.hr {
  border: 1px solid #e7e7e7;
}
.services_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 21px;
  text-transform: capitalize;

  color: #2f333b;
}
.services_wrapper {
  padding: 28px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  cursor: pointer;
}
.active {
  background: #b9beff;
  border: 1px solid #8388d0;
  transition: 0.3s;
}
.img_wrapper {
  width: 83px;
  height: 83px;
  background: #f1f1ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 37px 22px; */
  margin-bottom: 10px;
}
.img_wrapper_img {
  width: 83px;
  height: 83px;
  object-fit: cover;
  border-radius: 50%;
  /* padding: 37px 22px; */
  /* margin-bottom: 10px; */
}
.img_wrapper_comp {
  width: 30px;
  height: 30px;
  background: #f1f1ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 37px 22px; */
  margin-bottom: 10px;
}
.img_wrapper_img_comp {
  width: 30px;
  height: 30px;
  object-fit: contain;
  /* border-radius: 50%; */
  /* padding: 37px 22px; */
  /* margin-bottom: 10px; */
}

.services {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
  color: #2f333b;
}
.pro_time_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.name_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  color: #7d7d7d;
}
.selected_spare {
  /* width: 312px;
height: 71px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #f0f1ff; */
  padding: 9px 14px 14px 13px;
  margin-bottom: 4px;
}
.selectedSpareParts {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-bottom: 11px;
  padding: 0;
  border: 1px solid #f0f1ff;
}
.active {
  background: #b9beff;
  border: 1px solid #8388d0;
  transition: 0.3s;
}
.adding_item_botton {
  display: flex !important;
  align-items: flex-end !important;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #0761e9;
  border-radius: 4px;
}
.increment_btn span {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;
  padding: 6px 0;
  color: #0761e9;
}
.increment_btn button {
  background: none;
  border: none;
}

.servicesCards {
  /* height: 59vh; */
  overflow: auto;
}
.increment_btn {
  display: flex;
  border-radius: 17px;
}
.spare_name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-top: 10px;
  padding-bottom: 5px;
  text-transform: capitalize;

  color: #2f333b;
}
.spare_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  margin: 0;

  color: #2f333b;
}
.stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;

  color: #388e55;
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  /* padding-bottom: 4px; */
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 7px;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-top: 7px;

  color: #e22d2d;
}
.spares_wrapper {
  width: 100%;
  height: auto;
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 5px;
  /* border: 1px solid #f0f1ff; */
  /* margin-bottom: 15px; */
}
.adding_item_botton_spares {
  display: flex !important;
  /* align-items: flex-end !important; */
  align-items: center !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
  /* border: 1px solid #f0f1ff; */
  margin-top: 8px;
  margin-bottom: 20px;
}
.increment_btn_spares {
  display: flex;
  align-items: center;
  justify-content: center;
}
.increment_btn_spares span {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;
  padding: 6px;
  color: #0761e9;
}
.increment_btn_spares button {
  background: none;
  border: none;
}
.techInput {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #333544;
}
.est_tech_assignMain {
  display: flex;
  align-items: center;
}
.est_tech_assign {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  color: #2f333b;
  margin-bottom: 0;
  /* position: absolute;
  left: 31%;
  top: 20px; */
}
.main_section {
  display: flex;
  flex-direction: column;
}
.left_container {
  width: 25%;
}
.middle_container {
  width: 33%;
  border-right: 1px solid #e1e1e1;
}
.right_containers {
  width: 33%;
}
.estimate_service_txt {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  /* margin-left: 16px; */
  background-color: #fff;
  color: #2f333b;
  padding: 19px 15px;
  border-bottom: 1px solid #e1e1e1;
}
.estimate_spacing {
  padding: 0px 10px;
  height: calc(100vh - 335px);
  overflow-y: auto;
}
.estimate_services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 16px; */
  /* padding-right: 22px; */
  /* padding-bottom: 17px; */
  /* padding-left: 20px; */
  /* margin-left: 16px; */
  border: 1px solid #f0f1ff;
  margin-bottom: 7px;
  background: #fff;
  padding: 12px 18px;
}
.estimate_spare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-right: 22px;
  padding-bottom: 10px;
  padding-left: 20px;
  margin-left: 16px;
  border: 1px solid #f0f1ff;
  margin-bottom: 7px;
}
.estimate_services_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  text-transform: capitalize;
  color: #2f333b;
}
.estimate_services_qty {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  margin: 0;
  text-transform: capitalize;
  color: #2f333b;
}
.estimate_services_cost {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  text-align: right;
  text-transform: capitalize;

  color: #2f333b;
}
.estimate_service_left_img {
  display: flex;
  align-items: center;
  /* padding-right: 17px; */
}
.est_assign_date {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  color: #2f333b;
}
.right_containers p {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  color: #2f333b;
}
.sub_total {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #000000;
}
.curser_pointer {
  cursor: pointer;
}
.uploadLogo_btn {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  background-color: #f6f7f8 !important;
  padding: 10px 15px !important;
}
.otherBtn {
  width: 170px !important;
  /* height: 40px; */
  /* margin-top: 47px !important; */
  justify-content: space-between !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
}
.close_circle_btn {
  position: relative;
  top: -20px;
  right: -45px;
  height: 20px;
  width: 20px;
}
.vehicle_image {
  position: relative;
  top: -10px;
  max-height: 50px;
  min-height: 50px;
}
.colorSelector {
  border-radius: 0;
}
.delet_btn {
  display: flex;
}

.btn_dl_root {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.btn_del_main {
  border-radius: 50px !important;
  width: 200px;
  font-size: 12px !important;
  text-transform: none;
}
.chip_btn {
  cursor: pointer !important;
}

.vehicle_type_card {
  width: auto;
  background: white;
  display: flex;
  justify-content: center;
  padding: auto;
  position: relative;
}
.icon_car {
  position: relative;
  top: 2px;
  right: 20px;
}
.car_name {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  position: relative;
  top: 11px;
  left: 4px;
}
/* styles.module.css */

.radioIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.checkedIcon {
  border: 2px solid #007bff;
  opacity: 0.8;
}
.selected_services_card {
  margin-left: 0;
  margin-right: 0;
}
.left_container_estimate {
  width: 100%;
  border-right: 1px solid #e1e1e1;
}
.middle_container_estimate {
  width: 100%;
  border-right: 1px solid #e1e1e1;
}
.right_container_estimate {
  width: 100%;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
  .registratationbg {
    padding-bottom: 0px;
  }
  .selectedServicesTbMain {
    background-color: #fff;
    /* font-size: 16px; */
  }
  .selectedServicesTb {
    padding: 22px 16px !important;
    font-size: 14px !important;
    width: 50% !important;
  }
  .selectedServicesTbs {
    padding: 22px 16px !important;
    font-size: 14px !important;
    width: 100% !important;
  }
  .left_img {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .selected_services_card {
    margin-left: 10px;
    margin-right: 10px;
  }

  .increment_btn span {
    padding: 6px;
  }
  .main_section {
    flex-direction: row;
    border-bottom: 1px solid #e1e1e1;
  }
  .adding_item_botton_spares {
    margin-bottom: 0px;
  }
  .registratationbg {
    height: 85vh;
  }
  .bottom_section {
    position: sticky;
    /* bottom: 0px; */
    top: calc(100vh - 100px);
    right: 4px;
    width: 98%;
    /* background-color: #ffffff; */
    /* padding: 10px 10px; */
    /* border-top: 1px solid #e1e1e1; */
    z-index: 98;
  }
  .left_container_estimate {
    width: 33%;
    /* border-right: none; */
  }
  .middle_container_estimate {
    width: 33%;
    /* border-right: none; */
  }
  .right_container_estimate {
    width: 33%;
  }
  .colorInput {
    background-color: #ffffff;
  }
  .btnClose {
    display: flex;
    justify-content: flex-end;
  }
  .modal_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
  .btn_group {
    display: flex;
    justify-content: center;
    margin: 20px;
  }
  .btn_standard {
    margin-right: 20px;
  }
  .cancle_btn {
    width: 100% !important;
    padding: 9px 27px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    border: 1px solid grey !important;
    /* left: 101px; */
    /* top: 83px; */
    font-weight: 700 !important;
    font-size: 12px !important;
    background: white !important;
    color: #717171 !important;
  }

  .addVehicleBtn {
    width: 100% !important;
    padding: 9px 27px !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    /* left: 101px; */
    /* top: 83px; */
    font-weight: 700 !important;
    font-size: 12px !important;
    background: #0761e9 !important;
  }
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1200px) {
  .selectedSpareParts {
    display: flex;
    flex-direction: row;
    padding: 5px 13px 7px 0;
  }
}

/* .radioGroup{
background-color: #0761e9;
} */
/* YourStyles.module.css */
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.camera-video {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.iconInfo {
  display: flex;
  justify-content: center;
  padding: 20px;
}
