.iconButtonMore {
  padding: 0 !important;
}
.analyticsWrapper {
  padding: 1rem;
}
.todayStats {
  padding: 2rem 2rem 2rem 1rem;
  border-bottom: 1px solid #eee;
}
.todayStats h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 20px;
  /* identical to box height, or 80% */

  color: #000000;
}
.statsGrid {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.statsContainerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.revContainerTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}
.statsContainerTop h1 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}
.revContainerTop h1 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
}
.cardContainer {
  display: flex;
  flex-direction: row;
}
.serviceStatPending {
  width: 114px;
  height: 117.08px;
  background: rgb(234 111 83 / 15%);
  border-radius: 20px;
  margin: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serviceStatProgress {
  width: 114px;
  height: 117.08px;
  background: rgb(0 156 243 / 15%);
  border-radius: 20px;
  margin: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serviceStatReady {
  width: 114px;
  height: 117.08px;
  background: rgb(9 187 100 / 15%);
  border-radius: 20px;
  margin: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.serviceStatDelivered {
  width: 114px;
  height: 117.08px;
  background: rgb(7 97 233 / 15%);
  border-radius: 20px;
  margin: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.serviceStatPending h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;

  color: #ea6f53;
}
.serviceStatProgress h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;

  color: #009cf3;
}
.serviceStatReady h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;

  color: #09bb64;
}
.serviceStatDelivered h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  text-align: center;

  color: #0761e9;
}

.serviceStatPending p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.serviceStatProgress p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}
.serviceStatReady p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.serviceStatDelivered p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}
.revenueWrapper {
  height: 100%;
  padding: 1rem;
}
.revenueGrid {
  padding: 1rem;
  border-bottom: 1px solid #eee;
}
.cardContainerRevenue {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.revenueItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.revenueStat h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #000000;
}

.task_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #efefef;
  padding: 9px 26px 9px 26px;
  border-radius: 10px;
  margin: 1rem;
}
.service_img_wrapper {
  display: flex;
  align-items: center;
}
.technician_image {
  width: 50px;
  height: 50px;
}
.technician_details {
  margin-left: 0.8rem;
}
.technician_details p {
  padding: 0 !important;
  margin: 0 !important;
}
.task_details p {
  padding: 0 !important;
  margin: 0 !important;
}
.task_cost_details p {
  padding: 0 !important;
  margin: 0 !important;
}

.technician_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.technician_category_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
}
.task_no {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  /* Colors / Black / 100% */

  color: #000000;
}

.task_status_pending {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #ea6f53;

  mix-blend-mode: normal;
}
.task_status_completed {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #009cf3;

  mix-blend-mode: normal;
}

.task_cost {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  color: #0761e9;
}
.task_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #242424;

  mix-blend-mode: normal;
  opacity: 0.4;
}

.tab_label {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */
  text-transform: capitalize !important;
}
.page_title {
  font-weight: 600 !important;
  font-size: 10px !important;
  margin-top: 5px !important;
}
.inventoryNavBar {
  height: 40px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
.job_headbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding-bottom: 10px;
  padding: 10px;
}
.txt_job {
  color: #273a44;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}
.weekly_Btn {
  color: #000 !important;
  border: none !important;
  font-weight: 700 !important;
  /* background:#fff !important; */
}
.progress_bar_root {
  height: 210px;
  width: 200px;
  display: flex;

  margin: 0 auto;
}
.count_revenue {
  color: #0761e9;
  /* Headline S/Desktop/Semibold */
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.28px;
  padding-left: 8px;
}
