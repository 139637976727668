.thumbnailimage {
  margin-top: 4px;
  width: 100%;
  height: 156px;
  object-fit: cover;
  margin-bottom: 4px;
}
.thumbnailimagemain {
  width: 100%;
}
.viewallimages {
  font-size: 14px;
  /* height: 100%; */
  align-items: center;
  justify-content: center;
  background: #e1ecfc;
  color: #5b83be;
  text-align: center;
  padding: 12px 28px;
  cursor: pointer;
}
.viewallimagesmain {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
