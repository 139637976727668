.spares_wrapper {
  width: 100%;
  height: 100%;
  padding: 17px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  margin-bottom: 15px;
  overflow: auto;
  padding-bottom: 16px;
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
  color: #2f333b;
}
.img_wrapper {
  /* width: 110px;
  height: 110px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 37px 6px; */
  width: 55px;
  height: 55px;
  background: #f1f1ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 37px 22px; */
  margin-bottom: 10px;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.image_spare_part {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}
