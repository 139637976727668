.tab_label {
  margin-top: 12px !important;
  margin-left: 34px !important;
  margin-right: 43px !important;
  font-weight: 700 !important;
  font-size: 13px !important;
  line-height: 19px !important;
  text-transform: capitalize !important;
  color: #3b3232 !important;
}
.service_img_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 31px 22px 31px;
  background: #f0f1ff;
  margin-right: 0;
}
.service_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  text-align: center;
  text-transform: capitalize;
  padding-top: 24px;
  color: #2f333b;
}
.task_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #efefef;
  padding-right: 0;
  margin-left: 10px;
  background-color: #fff;
  margin-right: 10px;
  margin-bottom: 8px !important;
  /* padding: 24px 81px 23px 10px; */
  flex-direction: column;
  cursor: pointer;
}
.vehicle_details {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; */
  /* margin-right: 84px; */
  width: 94%;
  margin: 14px auto;
  text-align: center;
}
.vehicle_color {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vehicle_color span {
  margin-right: 12px;
  width: 13px;
  height: 13px;
  /* background: red; */
}
.vehicle_details p {
  color: #909090;
  font-size: 12px;
  margin: 6px 0;
}
.vehicle_color p {
  margin: 0 !important;
  color: #273a44;
  font-size: 14px;
  font-weight: 700;
}
.vehicle_details h5 {
  color: #273a44;
  font-size: 14px;
  font-weight: 700;
}

.vehicle_no {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  /* padding-top: 24px; */
  margin: 0;
  margin-right: 1em;
  /* padding-bottom: 2px; */
  color: #3b3232;
  text-transform: uppercase;
}
.vehicle_name {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  padding-bottom: 10px;
  text-transform: capitalize;

  color: #6c6c6c;
}
.vehicle_color {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  text-transform: capitalize;

  color: #3b3232;
}
.esd {
  /* margin-right: 201px; */
  width: 100%;
  margin: 12px;
  text-align: center;
}
.start_timing h4 {
  color: #273a44;
  font-size: 14px;
  font-weight: 700;
}
.esdTxt {
  color: #273a44;
  font-size: 13px;

  margin-bottom: 0;
  color: #273a44;
  font-family: Inter;
  font-weight: 500;
}
.startTime {
  /* margin-right: 52px; */
  width: 100%;
  text-align: center;
}
.statusBtn {
  display: flex;
}
.timer {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  margin: 0;

  padding-bottom: 4px;
  color: #3b3232;
}
.time {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #273a44;
}
.bay_requestSpare {
  display: flex;
  width: 100%;
  margin-right: 6px;
  justify-content: flex-start;
  margin-bottom: 8px;
  align-items: center;
}

.bay_btn {
  margin-right: 12px;
}
.pause_complete {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}
.request_btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 13px;
  align-items: center;
}

.pauseBtn {
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize;
  background: #00b5dd !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.startBtn {
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize;
  background: #1cba65 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.completeBtn {
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize;
  background: #0761e9 !important;
  margin-left: 22px !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.timer_complete {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #fa9348;
}
.time_complete {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;

  color: #3b3232;
}
.statusBtn_complete {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  align-items: center;
  color: #0761e9;
}
.colorBox {
  width: 13px;
  height: 13px;
  background: #f43a3a;
}
.root_container {
  /* height: 100vh; */
  /* overflow: auto; */
  /* padding-bottom: 150px; */
}
.bayBtn {
  width: 100px !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize;
  color: #2f333b !important;
  background: #dceaff !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.Request_Spare {
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize;
  background: #ea6e53 !important;
  color: #fff;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.return_Spare {
  width: 100% !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize;
  /* background: #ea6e53 !important; */
  color: #fff;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.modal_services {
  width: 50% !important;
  height: 50% !important;
}

.btnClose {
  display: flex;
  justify-content: flex-end;
}
.modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.root_container {
  /* overflow: none; */
  /* background: #F7F7FC; */
  /* height: 50%; */
  /* padding: 30px; */
  position: relative;
}
.btn_group {
  display: flex;
  justify-content: center;
  margin: 100px;
}
.btn_standard {
  margin-right: 20px;
}
.cancle_btn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: white !important;
  color: #717171 !important;
}
.addVehicleBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: #0761e9 !important;
}
.inActive {
  border: 1px solid #c8c8c8;
  background: #fff;
  /* padding: 10px; */
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.active {
  background: #dceaff;
  border: 1px solid #0761e9;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.bay_box {
  padding: 5px;
}

.footerMainPosition {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
  .tab_label {
    margin-top: 21px !important;
    margin-left: 41px !important;
    margin-right: 81px !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    text-transform: capitalize !important;
    color: #3b3232 !important;
  }
  /* .request_btn {
    width: 56%;
    flex-direction: column;
  } */
}
@media screen and (min-width: 768px) {
  .pause_complete {
    justify-content: space-between;
    width: 70%;
    align-items: center;
  }
  .task_wrapper {
    flex-direction: row;
    padding-right: 17px;
  }
  .service_img_wrapper {
    width: 12%;
    margin-right: 30px;
  }
  .vehicle_details {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; */
    /* margin-right: 84px; */
    width: 17%;
    margin: 0;
    text-align: left;
  }
  .esd {
    /* margin-right: 201px; */
    width: 12%;
    margin: 0;
    text-align: left;
  }
  .startTime {
    /* margin-right: 52px; */
    width: 16%;
    text-align: left;
  }
  .request_btn {
    width: 34%;
  }
  .vehicle_color {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 991px) {
  .service_img_wrapper {
    padding: 15px 7px 22px 7px;
  }
  /* .request_btn {
    width: 55%;
  } */
  /* .bay_requestSpare {
    width: 58%;
  } */
  .vehicle_details h5 {
    font-size: 16px;
  }
  .vehicle_details p {
    font-size: 14px;
  }
  .esdTxt {
    font-size: 16px;
    color: #273a44;
    font-family: Inter;
    font-weight: 500;
  }
  .start_timing h4 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1024px) {
  /* .bay_requestSpare {
    width: 58%;
  } */
}
@media screen and (min-width: 1200px) {
  /* .request_btn {
    width: 42%;
  } */
  .request_btn {
    width: 36%;
    display: flex;
    padding: 0;
    flex-direction: row;
  }
  .bay_requestSpare {
    display: flex;
    width: 60%;
    margin-right: 6px;
    margin-bottom: 0;
  }
}
.img_wrapper_img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  /* padding: 37px 22px; */
  /* margin-bottom: 10px; */
}
.spares_wrapper {
  width: 100%;
  height: auto;
  padding: 15px 25px;
  background: #ffffff;
  border-radius: 5px;
  /* border: 1px solid #f0f1ff; */
  /* margin-bottom: 15px; */
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  /* padding-bottom: 4px; */
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}

.show_more_button {
  color: #273a44 !important;
  font-weight: 600 !important;
}

.show_more_button:hover {
}
.sort_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.sort_button {
  background-color: #f0f0f0;
  margin-right: 10px;
  /* Add more styles as needed */
}
