.emo_root {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
}
.emo_title_txt {
  color: #b0b0b0;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.emo_title {
  text-align: center;
  padding: 6px;
}
.emo_sub_root {
  width: 63px;
  padding-left: 20px;
}
/* Rating.module.css */
.emo_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
}

.emoimage_root {
  cursor: pointer;
  transition: filter 0.3s ease;
}

.selected {
  filter: brightness(1.5); /* Adjust this value for the desired color change */
}
