.addProducteBtn {
  padding: 8px 12px;
  height: 37px;
  width: 130px !important;
  margin-left: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.addProducteCateBtn {
  padding: 8px 12px;
  height: 37px;
  width: 100% !important;
  margin-left: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.stockBtn {
  padding: 8px 12px;
  height: 37px;
  width: 100% !important;
  margin-right: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.inventoryNavBar {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  background-color: white;
  /* flex-direction: column; */
  /* height: 8vh; */

  position: fixed;
  width: 95%;
  z-index: 9999;
}
.inventorySearch {
  display: flex !important;
}
.filterButton {
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #2f333b !important;
  float: inline-end;
  margin-left: 20px !important;
}
/* spare part css */
.spares_wrapper {
  padding: 24px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 20px; */
}
.img_wrapper_img {
  width: 83px;
  height: 83px;
  object-fit: cover;
  border-radius: 50%;
  /* padding: 37px 22px; */
  /* margin-bottom: 10px; */
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  margin-top: 12px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #e22d2d;
}
.edit_item_botton_spares {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
}
.subHeading {
  font-family: "Inter";
  color: #2f333b;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
}
/* product inventory form css */

.pagrHeadingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-bottom: 3px solid #f5f5f5;
}

.pageHeadig {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  margin: 0;
}
.mainPanel {
  display: flex;
  margin-left: 20px;
  padding-top: 30px !important;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;
}
.rightPanel {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
  /* justify-content: center; */
  /* margin-top: -120px !important; */
}
.addImageSection {
  position: relative;
  top: -70px;
  width: 80%;
}
.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  color: #2f333b;
  margin-bottom: 8px;
}
.formControl {
  margin-bottom: 15px !important;
}
.outline_inputbox {
  background-color: white;
  border-radius: 0px !important;
}

.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 101px;
  background: white;
}
.uploadLogo_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}
.add_img_txt {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #0761e9;
}

.pageFooterContainer {
  bottom: -2px !important;
  right: 0px !important;
  display: flex;
  width: 100%;
  height: 11%;
  /* background-color: white; */
  justify-content: flex-end;
  padding: 21px 0 71px;
}

.root_contaer_box {
  background: #f7f7fc;
  height: 100%;
}
.backBtn {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  width: 53px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  text-transform: capitalize !important;
  color: #717171 !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  margin-right: 20px !important;
  margin-bottom: 15px !important ;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.saveBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;
  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e9 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  margin-right: 24px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.editBtn {
  font-size: 12px !important;
  width: 77px !important;
  align-items: center;
  display: flex;
  justify-content: center;
}

.uploadLogo_btn {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  background-color: #f6f7f8 !important;
  padding: 6px 13px !important;
  border-radius: 0px !important;
}

.img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 5px;
  background: #ffffff;
}

.vehiclesimagesAdd {
  height: 125px;
  display: flex;
  align-items: center;
}
.rootbar_main {
  /* height: 100vh; */
  /* padding-bottom: 30vh; */
  /* overflow: scroll; */
}
.cata_title {
  font-weight: 600 !important;
  color: #273a44;
  font-size: 14px;
  text-transform: capitalize;
}
.filter {
  display: flex;
}

.add_cata_btn {
  position: relative;
  right: 5px;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
  .inventoryNavBar {
    flex-direction: row;
  }
  .backBtn {
    margin-bottom: 0 !important ;
  }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1200px) {
}
.rootProduct {
  margin-top: calc(100vh - 700px);
}
