.spares_wrapper {
  width: 100%;
  height: auto;
  padding: 17px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  margin-bottom: 15px;
}
.img_wrapper {
  /* width: 83px;
  height: 83px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 37px 22px;
  margin-bottom: 10px; */
  width: 83px;
  height: 83px;
  background: #f1f1ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 37px 22px; */
  margin-bottom: 10px;
}
.img_wrapper_img {
  width: 83px;
  height: 83px;
  object-fit: cover;
  border-radius: 50%;
  /* padding: 37px 22px; */
  /* margin-bottom: 10px; */
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 7px;
  color: #388e55;
}
.adding_item_botton_spares {
  display: flex !important;
  /* align-items: flex-end !important; */
  align-items: center !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
  /* border: 1px solid #f0f1ff; */
  margin-top: 8px;
}

.gap_scilk {
  padding: 0 10px;
}
.slick-track {
  width: 0px !important;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
}
.increment_btn_spares {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-top: 7px;

  color: #e22d2d;
}
.btn_confrim {
  /* background: #0761e9; */
  /* display: flex; */
  /* width: 231px; */
  /* width: 86%; */
  /* border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  color: #0761e9;
  height: 40px;
}
