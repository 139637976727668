.invoiceTitle {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.invoiceTitle h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 20px;
  /* identical to box height, or 80% */
  color: #000000;
}
.invoiceHeader {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 8rem 0.5rem 3rem;
}

.invoiceHeader address {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  text-transform: capitalize;
  letter-spacing: 0.15px;

  /* Light/Text/Secondary */

  color: rgba(50, 71, 92, 0.6);
}
.dataTable {
  box-shadow: none !important;
  border: 1px solid #eee;
}
.invoiceFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* justify-content: space-between; */
  padding: 1rem 11rem 0rem 2rem;
}
.footerLeft address {
  font-family: "Inter";
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  letter-spacing: 0.15px;

  /* Light/Text/Secondary */

  color: rgba(50, 71, 92, 0.6);
}
.footerCaption {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  letter-spacing: 0.15px;

  /* Light/Text/Secondary */

  color: rgba(50, 71, 92, 0.6);
}
.footerValue {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  text-align: right;
  letter-spacing: 0.25px;

  /* Light/Text/Primary */

  color: rgba(50, 71, 92, 0.87);
}
.footerTable {
  width: 360px;
}
.buttonContainer {
  text-align: right;
  margin-top: 2rem;
}
.footerButton {
  margin-right: 2rem !important;
}
.root_stack {
  height: 99vh;
  /* overflow: auto; */
  padding-bottom: 150px;
}
/* Invoice.module.css */
.signatures {
  display: flex;
  justify-content: space-between;
  margin-top: 120px; /* Adjust the margin-top value to add space */
}

.signature {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  text-align: center;
  width: 30%; /* Adjust the width as needed */
}
