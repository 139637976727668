.history_navbar {
  padding: 10px !important;
  background-color: white;
  margin: 11px;
  border-radius: 10px;
  align-items: center;
  display: flex;
}
.back_btn_root {
  align-items: center;
  cursor: pointer;
}
.subRoot {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.main_root {
  /* height: 99vh;
  overflow: auto; */
  /* padding-bottom: 120px; */
}
.ve_num {
  background: #ffffff;
  border: 2px solid #273a44;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 7px;
  margin-bottom: 9px;
}
.ve_num_txt {
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
  text-transform: capitalize;
  color: #273a44;
}
.car_name {
  font-weight: 600;
  font-size: 16px;
  color: #273a44;
  margin: 0;
}
.car_name_description {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 110px;
  margin: 8px auto;
}
.icon_name_color {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  color: #273a44;
  margin: 0px;
}
.icon_squre_color {
  width: 12px;
  height: 12px;
  background: #000000;
  margin: 0px;
}
.name_root {
  display: flex;
}
.name_user {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #273a44;
  position: relative;
  left: 10px;
}
.number_user {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 2px;
  text-transform: capitalize;
  color: #273a44;
  position: relative;
  left: 10px;
}
.email_txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #273a44;
}
.visited_time {
  height: calc(100vh - 225px);
  overflow-y: auto;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 60px;
  margin-bottom: 20px;
}
.title {
  padding: 30px;
  display: flex;
  align-items: center;
}
.title_txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #333333;
  margin: 0px;
}
.title_root {
  border-bottom: 1px solid #dfdfdf;
}
.title_list_items {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #dfdfdf;
  align-items: center;
}
.title_list_items_date {
  display: flex;
}
.list_items_date_date {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #273a44;
  margin: 0px;
  position: relative;
  left: 5px;
  top: 5px;
}
.list_items_jobId {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-transform: capitalize;
  color: #273a44;
  margin: 0px;
}
.list_items_jobId_num {
  font-weight: 600;
}
.top_vehicle_km {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  padding-right: 20px;
  color: #273a44;
  padding-left: 21px;
}
.hader_info_sub {
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.hader_info_sub_job_id {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #273a44;
}
.hader_info_sub {
  padding: 0px;
  line-height: 11px;
}
.hader_info_sub_status {
  display: flex;
}
.hader_info {
  border-bottom: 1px solid #dfdfdf;
  padding: 14px;
}
.hader_info_sub_job_created {
  margin: 0px;
  font-size: 12px;
}
.iconSpecing {
  margin-right: 6px;
}
.hader_info_sub_job_del {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
  text-transform: capitalize;
  color: #494949;
  margin-right: 23px;
  margin-bottom: 19px;
  margin: 0px;
  padding-left: 20px;
}
.title_service {
  display: flex;
  justify-content: space-between;
}
.title_service {
  padding: 10px;
}
.service_txt {
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.view_more_txt {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #0761e9;
}

.comment_txt {
  /* color: #4e4d4d; */
  text-align: justify;
  font-size: 20px;
  font-family: Inter;
  line-height: 19px;
  text-transform: capitalize;
  padding: 18px;
  position: relative;
  bottom: 27px;
  color: #b0b0b0;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
  /* text-align: center; */
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 991px) {
  .subRoot {
    flex-direction: row;
  }
  .car_name_description {
    margin: 0;
  }
  .ve_num {
    margin-bottom: 0;
  }
  .visited_time {
    margin-bottom: 0px;
  }
}
@media screen and (min-width: 1200px) {
}
