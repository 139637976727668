.root_container {
  /* padding-top: 40px;
    padding-left: 55px; */
}
.root_card {
  background-color: whi;
}
.image_root {
  padding-top: 25px;
}
.card_title {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #273a44;
  padding-top: 18px;
}
.footer_root {
  position: absolute;
  width: 93%;
  bottom: 0px;
}

.footerMainPosition {
  position: absolute;
  bottom: 0;
  width: 100%;
}
