.root_container {
  padding-top: 40px;
  padding-left: 55px;
}
.root_card {
  background-color: whi;
}
.image_root {
  padding-top: 25px;
}
.card_title {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #273a44;
  padding-top: 18px;
}
.btnClose {
  display: flex;
  justify-content: flex-end;
}
.modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.btn_group {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.btn_standard {
  margin-right: 20px;
}
.cancle_btn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: white !important;
  color: #717171 !important;
}
.addVehicleBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: #0761e9 !important;
}
.formControl {
  margin-bottom: 15px !important;
}
.label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #273a44;
  margin-bottom: 6px;
}
