.navBar {
  background-color: white;
  color: black;
  box-shadow: none !important;
}

.sidebar_list_icon {
  color: #ffffff !important;
  width: 20px;
  height: 20px;
  padding: 0;
}

.navbar_list {
  padding-left: 20px;
}

.logo_box {
  position: absolute;
  left: 15%;
  right: 12.27%;
  top: 2.34%;
  bottom: 94.27%;
}

.search_icon {
  float: left;
}

.box_header {
  background-color: blue;
  position: absolute;
  left: 200px;
}

/* Sub nav bar */

.flag {
  height: 85px;
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px; */
  /* border-radius: 8px; */
  align-items: center;
  background-color: #273a44;
  padding: 24px;
  color: white;
  line-height: 13px;
}

.grid_border {
  border-left: 1px solid #c7c7c7;
}

.lineContainer {
  margin-top: 20px;
}

.subList:hover {
  background: #23a67b;
  color: #ffffff;
}

.contacDetailsGrid {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.googleMap {
  /* / border: 1px solid red; / */
  width: 100%;
  margin-top: 10px;
}

::placeholder {
  font-size: 12px;
}

._Institute {
  display: flex;
  justify-content: space-between;
}

._Institute_imageBox {
  display: flex;
  justify-content: left;
}

._image_txt {
  width: 20rem;
  margin-left: 20px;
  font-size: 16px;
}

._image_txt_logo {
  width: 15rem;
  font-size: 14px;
}

.styles._image_description {
  margin-left: 50px;
}

.upload_image {
  position: relative;
  border: none;
  padding: 5px;
  top: 80px;
  background-color: #1877f2;
  color: white;
}

.hader_txt {
  color: white;
}

.list_flat {
  display: flex;

  /* flex-wrap: nowrap; */
}

.list_item_flat {
  display: flex !important;
  color: black !important;
  font-size: 14px !important;
  /* border: 1px solid red !important; */
  justify-content: space-between;
}

.list_item {
  padding: 0px 5px;
  background-color: white;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  color: #69696b;
  text-align: center !important;
  padding-left: 15px;
}
.list_item_progress {
  padding: 0px 5px;
  background: #e7fff7;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  color: #69696b;
  text-align: center !important;
  padding-left: 15px;
}
.list_item_completed {
  padding: 0px 5px;
  background: #23a67b;
  color: #fff;
  margin-right: 4px;
  border-radius: 5px;
  height: 48px;
  text-align: center !important;
  padding-left: 15px;
}
.number_wrapperdiv {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.list_font_staging {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #69696b;
  border-radius: 50%;
}
.list_font_staging_progress {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #23a67b;
  border-radius: 50%;
  color: #23a67b;
}
.list_font_staging_completed {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffffff;
  border-radius: 50%;
}

.list_font {
  font-size: 14px !important;
  text-align: center !important;
}
.list_font_progress {
  font-size: 14px !important;
  text-align: center !important;
  color: #23a67b;
}
.list_font_completed {
  font-size: 14px !important;
  text-align: center !important;
}
.link {
  text-decoration: none;
  color: black;
}
.active {
  background-color: #23a67b;
  color: #fff;
}
.reasignModal {
  width: 70% !important;
}
.addVehicleBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: #0761e9 !important;
  color: #fff !important;
}
.addVehicleBtn_h3 {
  font-size: 21px;
  font-weight: 600;
  color: #273a44;
}
.services_addback {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
/* .addVehicleBtn:hover {
  background: #0761E9 !important;
} */
.backtocardScreeen {
  display: flex;
  background: #fff;
  align-items: flex-start;
  padding: 12px 9px;
  flex-direction: column;
}

.servicesTableScreen {
  padding: 16px 0;
}
.addVehicleBtn_back {
  background: #fff;
  border: 2px solid #e7e7e7;
  color: #717171;
  padding: 8px 23px;
  font-weight: 600;
  margin-right: 0;
  cursor: pointer;
  margin-bottom: 8px;
  font-size: 14px;
  text-align: center;
}
.tab_label {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */
  text-transform: capitalize !important;
}
.tabPanel {
  /* border-bottom: 1px solid #f4f8fb !important; */
}
.main_panel {
  display: flex;
}
.tabHeadingHome {
  display: flex;
  flex-direction: column;
}

.left_panel {
  /* width: 45%; */
  padding: 29px 7px 10px 8px;
  border-right: 1px solid #e7e7e7;
  padding-right: 4px;
  background-color: #fafafa;
}

.right_panel {
  width: 100%;
  background: #f7f7fc;
  /* border-left: 1px solid #e7e7e7; */
  height: 100vh;
  overflow: auto;
}
.dashboard_gallery {
  background: #e7e7e7;
  border-radius: 6px;
  padding: 13px 9px;
}
.TabpannelScroll {
  height: calc(100vh - 250px);
  overflow: auto;
  padding-right: 4px;
}
.service_img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid gray; */
  /* border-radius: 50%; */
  background-color: #ffffff;
  height: 75px;
  width: 75px;
}
.services_time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.vehilceImgTest {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 23px;
  margin-top: 11px;
}
.GalaryDesign {
  border: 1px solid #e1e1e1;
  padding: 12px 12px;
  border-radius: 0;
  background: #fff;
}
.customize {
  /* width: 175px; */
  /* height: 40px; */

  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */

  text-transform: capitalize !important;

  color: #2f333b !important;
}
.add_spare {
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */

  text-transform: capitalize !important;

  color: #2f333b !important;
}
.dollar {
  /* width: 109px; */
  /* height: 40px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */

  text-transform: capitalize !important;

  color: #ffffff !important;

  background: #0761e9 !important;
}
.top_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_vehicle_num {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #ffffff;
  margin-right: 23px;
}
.top_vehicle_name {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
  margin-right: 23px;
}
.top_vehicle_color {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;
  margin-right: 23px;

  color: #ffffff;
}
.top_vehicle_km {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
}
.top_status {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;

  color: #00e0ff;
}
.list_item_bar {
  display: flex;
}

.list_item_bar_txt {
  padding-right: 20px;
  font-size: 12px;
}
.list_item_bar_txt1 {
  padding-right: 47px;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
}
.hr {
  border: 1px solid #e7e7e7;
}
.button_functionlaity {
  display: block;
  margin-top: 15px;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
  .left_panel {
    /* width: 30%; */
    padding: 29px 7px 10px 8px;
    border-right: 1px solid #e7e7e7;
    padding-right: 4px;
    background-color: #fafafa;
  }
}
@media screen and (min-width: 768px) {
  .left_panel {
    /* width: 30%; */
    padding: 29px 7px 10px 8px;
    border-right: 1px solid #e7e7e7;
    padding-right: 4px;
    background-color: #fafafa;
  }
}
@media screen and (min-width: 991px) {
  .button_functionlaity {
    display: flex;
    justify-content: flex-end;
  }
  .right_panel {
    width: 100%;
    background: #f7f7fc;
    /* border-left: 1px solid #e7e7e7; */
    height: 100%;
    overflow: hidden;
  }
  .backtocardScreeen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #fff;
    align-items: center;
    padding: 12px 9px;
  }
}
@media screen and (min-width: 1200px) {
}

.spares_wrapper {
  padding: 24px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
}

.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
  color: #2f333b;
}

.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}

.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #388e55;
}

.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #e22d2d;
}

.edit_item_botton_spares {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
}
/* .modalStyle {
  height: 80%;
} */
.addVehicleBtn_img_header {
  /* font-family: Inter; */
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.formControl {
  margin-bottom: 15px !important;
}
.btn_group {
  display: flex;
  justify-content: center;
  margin: 20px;
}
.btn_standard {
  margin-right: 20px;
}
.cancle_btn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: white !important;
  color: #717171 !important;
}
.btnClose {
  display: flex;
  justify-content: flex-end;
}
.outline_inputbox {
  border-radius: 0px !important;
}
.estimateCard {
  border: 1px solid #f0f1ff;
  background-color: white;
  width: 100%;
  box-shadow: none !important;
  /* padding: 10px; */
}
.estimateCard_root {
  margin: 10px;
}
.row_track {
  display: flex;
  justify-content: space-between;
}

.txt_table {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.txt_table_header {
  font-weight: 800 !important;
  font-size: 14px !important;
}
.txt_table_bold {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.services_wrapper {
  padding: 28px 12px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
}
.services_wrapper:hover {
  background: #b9beff;
  border: 1px solid #8388d0;
  transition: 0.3s;
}
.img_wrapper {
  width: 83px;
  height: 83px;
  background: #f1f1ff;
  /* border-radius: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 37px 22px;
  margin-bottom: 10px;
}
.services {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
  color: #2f333b;
}
.pro_time_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.name_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  color: #7d7d7d;
}
.root_container {
  /* height: 54vh; */
  /* overflow: auto; */
  padding: 0px;
  overflow-x: hidden;
}
.modal_services {
  width: 70% !important;
  padding: 10px !important;
}
.root_spare {
  overflow: auto;
  height: 64vh;
}
.navBarSerchInput {
  padding-left: 10px;
}
.WelcomeMainHeader {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.root_box {
  width: 70% !important;
  padding: 10px !important;
}
.root_left {
  height: auto;
  overflow: auto;
  padding: 10px;
}
.root_right {
  height: auto;
  overflow: auto;
  border-right: none;
  padding: 10px;
  overflow-x: hidden;
}
.card_add {
  height: 75px;
  width: 100%;
  margin-bottom: 10px !important;
  padding: 24px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
}
.increment_btn_spares {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*  */

.inputForm {
  height: 10px !important;
}
.tabPanel {
  /* padding-bottom: 16px !important; */
  padding-bottom: 50px;
}
.stepper_wrapper {
  margin: 0;
  padding: 19px 86px 20px 86px !important;
}
.bottom_section {
  position: fixed;
  bottom: 0px;
  right: 10px;
  width: 94%;
  background-color: #ffffff;
  padding: 10px 10px;
  border-top: 1px solid #e1e1e1;
  z-index: 98;
}
.selectedServicesTbMain {
  background-color: #fff;
}
.nextPreviousButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 24px;
}
.backBtn {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */
  width: 53px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  text-transform: capitalize !important;

  color: #717171 !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  margin-right: 20px !important;
}
.saveBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;

  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e9 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  /* identical to box height */

  text-transform: capitalize !important;

  color: #ffffff !important;
  margin-right: 24px !important;
}
.hr {
  height: 2px;

  background: #f4f8fb;
}
.header {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;
  /* identical to box height */

  text-transform: capitalize;

  color: #2f333b;
}
.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #2f333b;
  margin-bottom: 8px;
}
.step_label {
  font-weight: 700 !important;
  font-size: 8px !important;
  line-height: 10px !important;
  /* identical to box height */

  text-align: center !important;
  text-transform: uppercase !important;

  color: #2f333b !important;
}
.img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 5px;
  background: #f7f8f9;
  /* cursor: pointer; */
}
.add_img_txt {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #0761e9;
}
.main_container {
  display: flex;
}
.left_container {
  width: 30%;
  border-right: 1px solid #e1e1e1;
}
.right_container {
  width: 70%;
}
.selectedServicesTb {
  padding: 22px 16px !important;
}
.serviceHeading {
  background-color: #fff;
  padding: 5px 0;
  border-bottom: 1px solid #e1e1e1;
}

.bottom_wrapper {
  /* width: 312px; */
  /* height: 80px; */

  /* background: #f0f1ff; */
  background: #ffffff;
  padding: 12px 23px 12px 20px;
  border-top: 1px solid #e1e1e1;
  /* border: 1px solid #f0f1ff; */
  /* position: fixed; */
  /* bottom: -24px; */
  z-index: 1;
  width: 100%;
}
.view_all {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #0761e9;
}
.total_price {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 0;
  text-transform: capitalize;

  color: #2f333b;
}
.colorPickerDEsign {
  border-radius: 0px !important;
}
.InputRegistrationDesign {
  border-radius: 0px !important;
  background-color: #fff;
  padding: 2px 2px;
}
.customerDetailsSpacing {
  margin-top: 20px;
}
.vehiclesimagesAdd {
  border: 2px solid #e7e7e7;
  /* width: 792px; */
  height: 125px;
  display: flex;
  align-items: center;
  padding-left: 9px;
  background: #fff;
  margin-bottom: 10px !important;
}
.selected_services {
  /* width: 312px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: row; */
  /* height: 80px; */
  margin-left: 17px !important;
  margin-right: 17px !important;
  background: #f0f1ff;
  border: 1px solid #f0f1ff;
  margin: 0;
  margin-bottom: 4px;
  padding: 10px 14px 10px 6px;
}
.registratationbg {
  background: #f7f7fc;
}
.left_img {
  display: flex;
  /* align-items: center; */
}
.service_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 7px;
  text-transform: capitalize;

  color: #2f333b;
}
.service_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 7px;
  color: #2f333b;
}
.etd_time {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;

  color: #2f333b;
}
.complaint_name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  padding-right: 10px;
  /* identical to box height, or 158% */

  text-transform: capitalize;
  margin: 0;
  color: #3b3232;
}
.TechniciancianName {
  font-size: 12px !important;
}
.complaint_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e8f1ff;
  margin: 0;
  padding: 10px 8px 10px 11px;
  margin-bottom: 7px;
  max-width: fit-content;
  line-break: anywhere;
}
.servicestxt {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  margin-top: 22px;
  color: #2f333b;
}
.hr {
  border: 1px solid #e7e7e7;
}
.services_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 21px;
  text-transform: capitalize;

  color: #2f333b;
}
.services_wrapper {
  padding: 28px 12px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
}
.services_wrapper:hover {
  background: #b9beff;
  border: 1px solid #8388d0;
  transition: 0.3s;
}
.services {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;
  color: #2f333b;
}
.pro_time_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.name_txt {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  text-align: center;
  margin: 0;
  color: #7d7d7d;
}
.selected_spare {
  /* width: 312px;
height: 71px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #f0f1ff;
  padding: 9px 14px 14px 13px;
  margin-bottom: 4px;
}
.selectedSpareParts {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-bottom: 11px;
  padding: 5px 13px 7px 0;
}
.adding_item_botton {
  display: flex !important;
  align-items: flex-end !important;
  /* width: 82px;
height: 24px;
left: 326px;
top: 191px; */
  margin-top: 20px;

  background: #ffffff;
  border: 1px solid #0761e9;
  border-radius: 4px;
}
.increment_btn span {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;
  padding: 6px;
  color: #0761e9;
}
.increment_btn button {
  background: none;
  border: none;
}

.servicesCards {
  /* height: 59vh; */
  overflow: auto;
}
.increment_btn {
  /* border: 1px solid #fed500; */
  /* margin: 0 10px; */
  display: flex;

  border-radius: 17px;
  /* padding: 0 6px; */
}
.spare_name {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-top: 10px;
  padding-bottom: 5px;
  text-transform: capitalize;

  color: #2f333b;
}
.spare_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  margin: 0;

  color: #2f333b;
}
.stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;

  color: #388e55;
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 7px;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-top: 7px;

  color: #e22d2d;
}
.spares_wrapper {
  /* width: 142.5px; */
  /* height: 198px; */
  padding: 17px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  margin-bottom: 15px;
}
.adding_item_botton_spares {
  display: flex !important;
  /* align-items: flex-end !important; */
  align-items: center !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
  border: 1px solid #f0f1ff;
  margin-top: 8px;
}
.increment_btn_spares {
  display: flex;
  align-items: center;
  justify-content: center;
}
.increment_btn_spares span {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;
  padding: 6px;
  color: #0761e9;
}
.increment_btn_spares button {
  background: none;
  border: none;
}
.techInput {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;

  color: #333544;
}
.est_tech_assignMain {
  display: flex;
  align-items: center;
}
.est_tech_assign {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  color: #2f333b;
  margin-bottom: 0;
  /* position: absolute;
  left: 31%;
  top: 20px; */
}
.footerMainPosition {
  position: fixed;
  bottom: 0;
}
.main_section {
  display: flex;
  width: 100%;
}
.left_container {
  width: 33%;
}
.middle_container {
  width: 33%;
  border-right: 1px solid #e1e1e1;
}
.right_containers {
  width: 33%;
}
.estimate_service_txt {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  text-transform: capitalize;
  /* margin-left: 16px; */
  background-color: #fff;
  color: #2f333b;
  padding: 19px 15px;
  border-bottom: 1px solid #e1e1e1;
}
.estimate_spacing {
  padding: 20px;
  min-height: 50vh;
}
.estimate_services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-top: 16px; */
  /* padding-right: 22px; */
  /* padding-bottom: 17px; */
  /* padding-left: 20px; */
  /* margin-left: 16px; */
  border: 1px solid #f0f1ff;
  margin-bottom: 7px;
  background: #fff;
  padding: 12px 18px;
}
.estimate_spare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-right: 22px;
  padding-bottom: 10px;
  padding-left: 20px;
  margin-left: 16px;
  border: 1px solid #f0f1ff;
  margin-bottom: 7px;
}
.estimate_services_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  text-transform: capitalize;

  color: #2f333b;
}
.estimate_services_qty {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  margin: 0;

  text-transform: capitalize;

  color: #2f333b;
}
.estimate_services_cost {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  text-align: right;
  text-transform: capitalize;

  color: #2f333b;
}
.estimate_service_left_img {
  display: flex;
  align-items: center;
  /* padding-right: 17px; */
}
.est_assign_date {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #2f333b;
}
.right_containers p {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #2f333b;
}
.sub_total {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #000000;
}
.curser_pointer {
  cursor: pointer;
}
.uploadLogo_btn {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  background-color: #f6f7f8 !important;
  padding: 8px 13px !important;
}
.otherBtn {
  width: 170px !important;
  /* height: 40px; */
  /* margin-top: 47px !important; */
  justify-content: space-between !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
}
.close_circle_btn {
  position: relative;
  top: -20px;
  right: -45px;
  height: 20px;
  width: 20px;
}
.vehicle_image {
  position: relative;
  top: -10px;
  max-height: 50px;
  min-height: 50px;
}
.colorSelector {
  border-radius: 0;
}
.delet_btn {
  display: flex;
}
.welcomeImg {
  width: 85%;
  height: 100%;
}
.vehiclesDetailsTaab {
  background-color: #f7f7fc;
  height: 63vh;
  /* overflow: auto; */
}

.lunchOffcanvas {
  display: block;
  background: #fff;
  padding: 7px 7px;
}
.left_panel_root {
  display: none;
}
.viewJobsBtn {
  background: #fff;
  border: 2px solid #e7e7e7;
  color: #717171;
  padding: 8px 13px;
  font-weight: 600;
  font-size: 14px;
  width: 37%;
  text-align: center;
}
.viewJobsRoot {
  width: 100%;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 550px) {
}
@media screen and (min-width: 600px) {
  .services_addback {
    flex-direction: row;
  }
  .addVehicleBtn_back {
    margin-right: 10px;
  }
  .vehiclesDetailsTaab {
    background-color: #f7f7fc;
    height: 100%;
    overflow: none;
  }
  .reasignModal {
    width: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .addVehicleBtn_back {
    background: #fff;
    border: 2px solid #e7e7e7;
    color: #717171;
    padding: 8px 23px;
    font-weight: 600;
    margin-right: 16px;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 14px;
  }
  .estimateCard {
    width: 50%;
  }
  .vehiclesDetailsTaab {
    background-color: #f7f7fc;
    height: 64vh;
    /* overflow: auto; */
  }
  .viewJobsBtn {
    width: 27%;
  }
  .root_left {
    border-right: 1px solid #d7dbdd;
  }
  .lunchOffcanvas {
    display: none;
  }
  .left_panel_root {
    display: block;
  }
}
@media screen and (min-width: 991px) {
  .root_container {
    /* height: 60vh; */
    /* overflow: auto; */
    padding: 30px;
    overflow-x: hidden;
  }
  .root_spare {
    overflow: hidden;
    height: 70vh;
  }
  .root_left {
    height: 60vh;
    overflow: auto;
    border-right: 1px solid #d7dbdd;
    padding: 10px;
  }
  .root_right {
    height: 60vh;
    overflow: auto;
    border-right: none;
    padding: 10px;
    overflow-x: hidden;
  }
  .reasignModal {
    width: 30% !important;
  }
}
@media screen and (min-width: 1024) {
}
.service_img {
  width: 100%;
}
