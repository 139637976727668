.inventoryNavBar {
  display: flex !important;
  justify-content: space-between !important;
  background-color: white;
  line-height: 20px;
  padding: 21px;
  align-items: center;
}
.txt_title {
  color: #273a44;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  margin: 0px;
}
.btn_group {
  display: flex;
  justify-content: space-around;
  width: 180px;
}
.approvebtn {
  font-size: 12px !important;
  border-radius: 0px !important;
  text-transform: none !important;
}
.rejectbtn {
  color: #f00 !important;
  border: 1px solid rgba(255, 0, 0, 0.12) !important;
  border-radius: 0px !important;
  font-size: 12px !important;
  text-transform: none !important;
}
.title_modal {
  display: flex;
  justify-content: center;
}
.btn_modal_group {
  display: flex;
  justify-content: center;
  padding: 20px;
  align-items: center;
}
.approvebtn_modal {
  position: relative;
  left: 20px;
  font-size: 12px;
  border-radius: 0px !important;
  text-transform: none !important;
}
.cancelbtn {
  border-radius: 0px !important;
  font-size: 12px;
  text-transform: none !important;
}
.sub_txt_modal {
  font-weight: 600;
}
.footer_root {
  position: absolute;
  width: 93%;
  bottom: 0px;
}
.footerMainPosition {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.sparerequestModal {
  width: 90%;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .sparerequestModal {
    width: 34%;
    margin: auto;
  }
}
