.flag {
  /* height: 85px;*/
  padding: 13px 15px 0px 21px !important;
  /* display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px; */
  /* border-radius: 8px; */
  align-items: center;
  /* background-color: #273a44; */
  background: #f0f0f0;

  /* padding: 24px; */
  color: white;
  line-height: 13px;
}
.servicesLabel {
  color: #6a6a6a;
}
.top_wrapper {
  display: block;
  justify-content: space-between;
  align-items: center;
  /* padding-bottom: 11px; */
  border-bottom: 1px solid #4d575d;
  /* padding: 0; */
  margin-bottom: 10px;
  /* padding-bottom: 28px; */
}
.top_vehicle_num {
  background: white;
  margin-right: 23px;
  padding: 10px;
  color: #273a44;
  border: 3px solid #273a44;
  font-family: Arial, sans-serif;
  /* box-shadow: 2px 4px 5px 1px #cccccc; */
  font-size: 14px;
  border-radius: 6px;
  margin-bottom: 6px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.iconSpecing {
  margin-right: 6px;
}
.top_vehicle_name {
  /* width: 50%; */
  font-weight: 800;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #494949;
  margin-right: 23px;
  text-wrap: nowrap;
}
.top_vehicle_color {
  font-weight: 800;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #494949;
  margin-right: 23px;
  display: flex;
  align-items: center;
}
.top_vehicle_km {
  font-weight: 800;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #494949;
  margin-right: 23px;
  width: 100%;
  margin-bottom: 19px;
}
.top_status {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  margin: 0;

  color: #00e0ff;
}
.list_item_assigned {
  padding-right: 20px;
  font-size: 12px;
  margin-bottom: 5px;
}
.list_item_bar {
  display: block;
  /* margin: 16px 0 8px; */
}

.list_item_bar_txt {
  font-weight: 800;
  font-size: 13px;
  line-height: 15px;
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #000;
  margin-right: 23px;
}
.list_item_bar_txt1 {
  padding-right: 0px;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #ffffff;
}
.vehiclesDetails {
  display: block;
  /* margin-bottom: 5px; */
}
.statuschip {
  font-size: 14px;
  background: #885eff1f;
  padding: 10px;
  font-weight: 600;
  color: #885eff;
  margin-right: 8px;
  margin-bottom: 6px;
  width: 129px;
  margin: 0px;
  text-align: center;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 550px) {
  .top_vehicle_num {
    font-size: 18px;
    width: 50%;
  }
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 991px) {
  /* .top_wrapper {
  display: flex;
  justify-content: space-between;
} */
  .vehiclesDetails {
    display: flex;
    align-items: center;
  }
  .list_item_bar {
    display: flex;
  }
  .top_wrapper {
    display: flex;
    padding-bottom: 0;
    border-bottom: none;
    align-items: center;
  }
  .list_item_bar_txt {
    padding-right: 20px;
    font-size: 12px;
    margin-bottom: 0;
  }
  .top_vehicle_km {
    margin-bottom: 5px;
  }
  .top_wrapper {
    padding-bottom: 0;
  }
  .list_item_bar_txt1 {
    padding-right: 47px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1199px) {
  .top_vehicle_num {
    margin-bottom: 0px;
  }
}
.setStatus {
  display: flex;
  flex-direction: column !important;
}
.button_set_delivery {
  /* position: relative; */
  /* padding-top: 10px; */
  /* bottom: 10px;
  left: 5px; */
}
.del_btn {
  border-radius: 0px !important;
  text-transform: none !important;
  box-shadow: none !important;
  font-size: 12px !important;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1200px) {
  .setStatus {
    display: flex;
    flex-direction: row;
  }
}
.title_modal {
  display: flex;
  justify-content: center;
}
.label {
  padding-top: 10px;
  line-height: 50px;
  font-size: 13px !important;
}
.label_ {
  position: relative !important;
  left: 10px !important;
  line-height: 45px !important;
  font-size: 13px !important;
}
.select_filed {
  border-radius: 0px !important;
}

.placeholder_payment {
  color: #9596ac !important;
  font-size: 12px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.btn_modal_group {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.cancelbtn {
  border-radius: 0px !important;
  font-size: 14px !important;
  text-transform: none !important;
  border: 1px solid #e7e7e7 !important;
}
.btn_modal {
  border-radius: 0px !important;
  font-size: 13px !important;
  text-transform: none !important;
  position: relative;
  left: 10px;
}
.rootMarkbtn {
  padding-top: 10px;
}
.btnMark {
  width: 110px;
  font-size: 12px !important;
}
