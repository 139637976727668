.inventoryNavBar {
  display: flex !important;
  justify-content: space-between !important;
  background-color: white;
  padding: 21px;
  line-height: 20px;
  align-items: center;
}
.txt_title {
  color: #273a44;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  margin: 0px;
}
.btn_group {
  display: flex;
  justify-content: space-around;
  width: 220px;
}
.btn_modal_group {
  display: flex;
}
.label {
  padding-top: 10px;
  line-height: 50px;
  font-size: 13px !important;
}
.label_ {
  position: relative !important;
  left: 10px !important;
  line-height: 45px !important;
  font-size: 13px !important;
}
.title_modal {
  display: flex;
  justify-content: center;
}
.btn_modal_group {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.Deliveredbtn_modal {
  position: relative;
  left: 10px;
  border-radius: 0px !important;
  font-size: 12px;
  text-transform: none !important;
  font-size: 14px !important;
}
.cancelbtn {
  border-radius: 0px !important;
  font-size: 13px !important;
  text-transform: none !important;
  border: 1px solid #e7e7e7 !important;
}

.btn_modal {
  border-radius: 0px !important;
  font-size: 13px !important;
  text-transform: none !important;
  position: relative;
  left: 10px;
}

.placeholder_payment {
  color: #9596ac !important;
  font-size: 12px !important;
  font-family: Inter !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.paidbtn {
  border: 1px solid rgba(7, 97, 233, 0.12) !important;
  font-size: 12px !important;
  text-transform: none !important;
}
.paymentModal {
  width: 90%;
  margin: auto;
}
.footerMainPosition {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.sparerequestModal {
  width: 90%;
  margin: auto;
}

@media screen and (min-width: 768px) {
  .sparerequestModal {
    width: 34%;
    margin: auto;
  }
}
