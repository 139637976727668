.services_wrapper {
  background: #ffffff;
  /* border: 1px solid #e1e1e1; */
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 49px 13px 34px 10px;
  cursor: pointer;
  height: 200px;
}

.services_name {
  text-align: center;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 13px;
  color: #2f333b;
}
.services_status {
  text-align: center;
  font-style: normal;
  font-weight: 700 !important;
  /* font-size: 13px; */
  line-height: 18px;
  text-transform: uppercase;
  /* margin-bottom: 13px; */
  color: #2f333b;

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}
.img_wrapper {
  margin-bottom: 21px;
}
.time_txt {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;

  color: #8a8a8a;
}
.pro_time_txt {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 11px;
  color: #09bb64;
}
.name_txt {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;

  color: #333544;
}
.status_txt {
  font-weight: 700;
  font-size: 10px;
  line-height: 155.02%;
  /* identical to box height, or 16px */

  text-transform: capitalize;
  margin: 0;
  padding: 0;
  color: #09bb64;
}
