/* width */
::-webkit-scrollbar {
  width: 0.6vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #393c45; 
  border-radius: 1em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0761e9; 
}
body {
  background-color: #ffffff;
}
td{
  white-space: nowrap;
}
th{
  white-space: nowrap;
}
.MuiTablePagination-selectLabel {
  margin: 0;
}
.offcanvas {
  z-index: 999999 !important;
}
.MuiTablePagination-displayedRows {
  margin: 0;
}
.slick-track{ margin-left: 0 !important; }