body {
  background-color: #f0f1ff;
}
.selectedspares {
  height: 90vh;
  background-color: white;
  border-right: 1px solid #e2e2e2;
  /* margin-top: 64px; */
  width: 100%;
  position: fixed;
  width: 25%;
  /* top: 64px; */
}
.child_button {
  position: sticky;
  top: calc(100vh - 100px);
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  padding-bottom: 21px;
}
.spareparts {
  height: auto;
  background-color: white;
  position: relative;
  top: 69px;
}
.title {
  padding: 30px;
  border-bottom: 3px solid #f0f1ff;
  display: flex;
  align-items: center;
}
.txt_spare_title {
  color: #353535;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0px;
}
.title_spareaprt {
  padding: 30px;
}
.title_categories {
  padding: 30px;
}
.categories_txt {
  color: #2f333b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
}
.card_test {
  height: 100px;
  width: 100%;
  border: 1px solid black;
}
.slider_root {
  padding: 20px;
  position: relative;
  bottom: 30px;
}
.root_cata_stage {
  position: relative;
  bottom: 45px;
}
.btn_confrim {
  background: #0761e9;
  display: flex;
  /* width: 231px; */
  width: 86%;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  /* height: 40px; */
}
.add_part_card {
  width: 100%;
  /* height: 71px; */
  flex-shrink: 0;
  border: 1px solid #f0f1ff;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}
.add_part {
  justify-content: center;
  padding: 0 20px 10px;
}
.img_txt_track {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.card_title_root {
  padding: 6px;
  /* position: relative; */
  left: 16px;
}
.increment_btn_spares {
  display: flex;
  align-items: center;
  justify-content: center;
}
.increment_btn {
  background: none;
  border: none;
}

.adding_item_botton {
  display: flex !important;
  align-items: flex-end !important;
  background: #ffffff;
  border: 1px solid #0761e9;
  border-radius: 4px;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: 7px;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 7px;
  margin-top: 7px;

  color: #e22d2d;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
  .add_part_card {
    padding: 11px 8px;
  }
  .img_txt_track {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1150px) {
  .img_txt_track {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }
}
@media screen and (min-width: 1200px) {
}
