.vehicle_wrapper {
  padding: 23px 19px 28px 16px !important;
  background: #ffffff;
  /* border: 1px solid #e7e7e7; */
  cursor: pointer;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e4e4e4;
  margin: 5px 0 8px;
  border-radius: 5px;
}
.vehicle_wrapper_active {
  padding: 23px 19px 28px 16px !important;
  /* background: #ffffff; */
  /* border: 1px solid #e7e7e7; */
  cursor: pointer;
  /* box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05); */
  /* border: 1px solid #e4e4e4; */
  border-radius: 5px;
  margin: 5px 0 8px;
  background: #0f5498;
  transition: 0.5s;
}
.vehicle_wrapper_active p {
  color: #fff !important;
  margin-bottom: 7px;
}
.trashcardOutline {
  background: #fff;
  height: 30px;
  width: 30px;
  padding: 0 5px;
  border-radius: 50%;
}
.vehicle_wrapper_active {
  padding: 23px 19px 28px 16px !important;
  border-radius: 5px;
  background: #273a44;
  /* border: 1px solid #f4f8fb; */
}
.vehicle_name {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-transform: capitalize;

  color: #273a44;
}
.vehicle_number {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #909090;
}
.vehicle_number_active {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */
  text-transform: capitalize !important;
  color: #fff !important;
}
.vehicle_wrapper p {
  margin-bottom: 7px;
}
.vehicle_status {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 17px;
  /* identical to box height */

  text-align: right;
  text-transform: capitalize;

  color: #009cf3;
}
.delete_icon {
  font-weight: 700;
  font-size: 14px !important;
  line-height: 17px;
  /* identical to box height */

  text-align: right;
  text-transform: capitalize;
}
.vehicle_owner {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #273a44;
}
.vehicle_owner_n {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-transform: capitalize;

  color: #273a44;
}
.modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 10px;
  color: #34495e !important;
}
.btn_group {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.btn_standard {
  margin-right: 20px;
}

.cancle_btn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: white !important;
  color: #717171 !important;
}

.addVehicleBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background-image: linear-gradient(
    to right,
    #d31027 0%,
    #ea384d 51%,
    #d31027 100%
  ) !important;
}
.addVehicleReOpenBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background-image: linear-gradient(
    to right,
    #10d324 0%,
    #38ea4d 51%,
    #10d31a 100%
  ) !important;
}
.iconInfo {
  display: flex;
  justify-content: center;
  padding: 20px;
}
