.linkWrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
}
.link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #0761e9;
  text-decoration: none;
}
.otplink {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #0761e9;
  text-decoration: none;
}
.disabledLink {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: #9ba5b4;
  text-decoration: none;
}
.LoginImage {
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PoweredBy p {
  margin-bottom: 0;
  color: #4d5560;
  font-size: 13px;
}
.PoweredByFrom p {
  margin-bottom: 0;
  color: #4d5560;
  margin-right: 10px;
  font-size: 13px;
}
.login_formMain {
  height: 93vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.login_header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 32px; */
  margin: 36px 0 10px;
  letter-spacing: 0.15px;
  color: rgba(50, 71, 92, 0.87);
}
.login_paragraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(50, 71, 92, 0.6);
  margin-bottom: 24px;
}
.header_wrap {
  margin-top: 73px;
  margin-bottom: 33px;
}
.input {
  width: 400px !important;
  height: 40px !important;
  /* left: 216px; */
  /* top: 703px; */

  background: #ffffff;
  border: 1px solid #cdcdcd !important;
  border-radius: 4px !important;
}
.loginBtn {
  width: 400px !important;
  height: 40px !important;
  background: #0761e9 !important;
  border-radius: 4px !important;
  justify-content: center;
}
.loginBtnWrap {
  display: flex !important;
  justify-content: center !important;
}
.logo_header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.company_name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  /* text-align: center; */
  color: #273a44;
}
.company_name1 {
  color: #ea6f53;
}
.login_form {
  /* margin: 0 60px; */
  width: 74%;
}
.company_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  /* position: absolute;
  bottom: 5%;
  left: 75%; */
}
.LoginImage1 {
  padding-top: 90px;
  padding-left: 13px;
}
.powered_by {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 15px;
  text-transform: capitalize;
  color: #4d5560;
}
.PoweredBy {
  padding: 12px 0px;
}
.PoweredByFrom {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 12px 0;
}
.LoginImage img {
  width: 90%;
}
@media screen and (min-width: 991px) {
  .LoginImage img {
    width: 57%;
  }
  .LoginImage {
    height: 82vh;
  }
}
