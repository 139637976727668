.addProducteBtn {
  padding: 8px 12px;
  height: 37px;
  /* width: 130px !important; */
  margin-left: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.inventoryNavBar {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  background-color: white;
}
.inventorySearch {
  display: flex !important;
}
.filterButton {
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #2f333b !important;
  float: inline-end;
  margin-left: 20px !important;
}
/* spare part css */
.spares_wrapper {
  width: 142.5px;
  height: 198px;
  padding: 24px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 20px; */
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #e22d2d;
}
.edit_item_botton_spares {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
}
.subHeading {
  font-family: "Inter";
  color: #2f333b;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
}
/* product inventory form css */
.pagrHeadingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-bottom: 3px solid #f5f5f5;
}
.pageHeadig {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  margin: 0;
}
.mainPanel {
  display: flex;
  margin-left: 20px;
  padding-top: 30px !important;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;
}
.rightPanel {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
  /* justify-content: center; */
  /* margin-top: -120px !important; */
}
.addImageSection {
  position: relative;
  top: -70px;
  width: 80%;
}
.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  color: #2f333b;
  margin-bottom: 8px;
}
.formControl {
  margin-bottom: 15px !important;
}
.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 101px;
  background: #f8f8f8;
}
.uploadLogo_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add_img_txt {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #0761e9;
}
.pageFooterContainer {
  /* position: fixed; */
  bottom: -2px !important;
  right: 0px !important;
  display: flex;
  width: 100%;
  height: 11%;
  /* background-color: white; */
  justify-content: flex-end;
  padding: 21px;
}
.backBtn {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  width: 53px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  text-transform: capitalize !important;
  color: #717171 !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  margin-right: 20px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.saveBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;
  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e9 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  margin-right: 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.editBtn {
  font-size: 12px;
  /* width: 22px;
      height: 15px; */
}

.root_contaer_box {
  background: #f7f7fc;
  height: 100%;
}

.outline_inputbox {
  background-color: white;
  border-radius: 0px !important;
}

.addProducteBtn {
  padding: 8px 12px;
  height: 37px;
  width: 130px !important;
  margin-left: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.addProducteCateBtn {
  padding: 8px 12px;
  height: 37px;
  width: 100% !important;
  margin-right: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.inventoryNavBar {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  background-color: white;
}
.inventorySearch {
  display: flex !important;
}
.filterButton {
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #2f333b !important;
  float: inline-end;
  margin-left: 20px !important;
}
/* spare part css */
.spares_wrapper {
  /* width: 142.5px;
  height: 198px; */
  padding: 24px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  /* margin-left: 20px; */
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin-top: 12px;
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #e22d2d;
}
.edit_item_botton_spares {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
}
.subHeading {
  font-family: "Inter";
  color: #2f333b;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
}
/* product inventory form css */
.pagrHeadingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 95px;
  background: #ffffff;
  border-bottom: 3px solid #f5f5f5;
}
.pageHeadig {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  padding-left: 20px;
  margin: 0;
}
.mainPanel {
  display: flex;
  margin-left: 20px;
  padding-top: 30px !important;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;
}
.rightPanel {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
}
.addImageSection {
  position: relative;
  top: -70px;
  width: 80%;
}
.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  color: #2f333b;
  margin-bottom: 8px;
}
.formControl {
  margin-bottom: 15px !important;
}
.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 101px;
  background: #f8f8f8;
}
.uploadLogo_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add_img_txt {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #0761e9;
}
.pageFooterContainer {
  /* position: fixed; */
  bottom: -2px !important;
  right: 0px !important;
  display: flex;
  width: 100%;
  height: 11%;
  /* background-color: white; */
  justify-content: flex-end;
  padding: 21px 0 71px;
}

.backBtn {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  width: 53px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  text-transform: capitalize !important;
  color: #717171 !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  margin-right: 20px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.saveBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;
  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e9 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  margin-right: 0 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.disableBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;
  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e990 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  margin-right: 24px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.editBtn {
  font-size: 12px;
  /* width: 22px;
  height: 15px; */
}

.root_contaer_box {
  background: #f7f7fc;
  height: 100%;
}
.outline_inputbox {
  background-color: white;
  border-radius: 0px !important;
}

.uploadLogo_btn {
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  background-color: #f6f7f8 !important;
  padding: 8px 13px !important;
}
.img_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  margin: 5px;
  background: #ffffff;
}
.vehiclesimagesAdd {
  height: 125px;
  display: flex;
  align-items: center;
}
.pagrHeadingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-bottom: 3px solid #f5f5f5;
}
.InventoryMainContent {
  height: 99vh;
  overflow: auto;
  padding-bottom: 150px;
}
.cata_title {
  font-weight: 600;
  font-size: 14px;
}
.filter {
  display: flex;
}
.StockINHead {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  align-items: center;
  padding: 9px 9px;
  margin-bottom: 3px;
}
.StockINHeadImg {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 12%;
}
.StockINHead p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: #388e55;
}
.StockINHead h4 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: #273a44;
}
.StockEditBtn {
  font-size: 14px;
}
.StockINHead img {
  width: 31%;
  display: none;
}
.fontchange_in {
  font-family: sans-serif;
}
.select_placeholder {
  color: #9596ac;
  font-size: 12px;
  font-family: Inter;
  text-transform: capitalize;
}
.datepicker {
  height: 40px;
  border-radius: 0px;
}
.css-1pz006l-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 0px !important;
  padding-right: 14px;
}
@media screen and (min-width: 600px) {
  .StockINHead img {
    width: 31%;
    display: block;
  }
}
.modal_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 10px;
  color: #34495e !important;
}
.iconInfo {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.btn_group {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.btn_standard {
  margin-right: 20px;
}

.cancle_btn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid grey !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background: white !important;
  color: #717171 !important;
}

.addVehicleBtn {
  width: 100% !important;
  padding: 9px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  /* left: 101px; */
  /* top: 83px; */
  font-weight: 700 !important;
  font-size: 12px !important;
  background-image: linear-gradient(
    to right,
    #d31027 0%,
    #ea384d 51%,
    #d31027 100%
  ) !important;
}
