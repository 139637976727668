.footer_root {
  display: flex;
  justify-content: space-between;
  padding: 7px 15px;
  align-items: center;
}
.powerBy_root {
  display: flex;
  justify-content: space-around;
  width: 155px;
}

.txt_company {
  color: #4d5560;
  font-size: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.txt_company_Powered {
  color: #4d5560;
  font-size: 10px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  top: 6px;
}
.footer_root_main1 {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  background: none;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
  .txt_company {
    font-size: 12px;
  }
  .txt_company_Powered {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) {
  .footer_root_main {
    width: 89%;
  }
}
@media screen and (min-width: 850px) {
  .footer_root_main {
    width: 91%;
  }
}
@media screen and (min-width: 991px) {
  .footer_root_main {
    width: 92%;
  }
}
@media screen and (min-width: 1200px) {
  .footer_root_main {
    width: 93%;
  }
}
@media screen and (min-width: 1300px) {
  .footer_root_main {
    width: 94%;
  }
}
@media screen and (min-width: 1400px) {
  .footer_root_main {
    width: 95%;
  }
}
