body {
  background-color: #f0f1ff;
}
.selectedspares {
  height: 95vh;
  background-color: white;
  margin-top: 69px;
  width: 100%;
}
.child_button {
  position: absolute;
  bottom: 50px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
}
.spareparts {
  /* height: auto; */
  background-color: white;
  /* position: relative; */
  /* margin-top: 28px; */
  border-top: 1px solid #e3e3e3;
  padding-left: 13px;
}
.title {
  padding: 30px;
  border-bottom: 3px solid #f0f1ff;
  display: flex;
  align-items: center;
}
.txt_spare_title {
  color: #353535;
  font-size: 15px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0px;
}
.title_spareaprt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 33px;
}
.title_categories {
  padding: 19px 0;
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.categories_txt {
  color: #2f333b;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
}
.card_test {
  height: 100px;
  width: 100%;
  border: 1px solid black;
}
.slider_root {
  padding: 20px;
  position: relative;
  bottom: 30px;
}
.root_cata_stage {
  /* position: relative; */
  bottom: 45px;
}
.btn_confrim {
  background: #0761e9;
  display: flex;
  width: 231px;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 40px;
}
.search_main {
  width: 30%;
  /* position: relative; */
  left: 20px;
}
.search {
  position: relative;
  border-radius: 4px;
  /* background-color: rgba(255, 255, 255, 0.15);
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
  } */
  margin-right: 16px;
  margin-left: 0;
  padding-left: 10px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  position: relative;
  right: 0;
}

.searchIcon {
  padding: 8px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add_part_card {
  width: 100%;
  height: 71px;
  flex-shrink: 0;
  border: 1px solid #f0f1ff;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}
.add_part {
  justify-content: center;
  padding: 20px;
}
.img_txt_track {
  display: flex;
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.card_title_root {
  padding: 6px;
  position: relative;
  left: 16px;
}
.increment_btn_spares {
  display: flex;
  align-items: center;
  justify-content: center;
}
.increment_btn {
  background: none;
  border: none;
}

.adding_item_botton {
  display: flex !important;
  align-items: flex-end !important;
  background: #ffffff;
  border: 1px solid #0761e9;
  border-radius: 4px;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
  .spareparts {
    /* height: auto; */
    background-color: white;
    /* position: relative; */
    /* margin-top: 64px; */
    border-top: none;
  }
}
@media screen and (min-width: 991px) {
}
@media screen and (min-width: 1200px) {
}
