.top_vehicle_num {
  background: white;
  margin-right: 23px;
  padding: 10px;
  color: #273a44;
  border: 3px solid #273a44;
  font-family: Arial, sans-serif;
  /* box-shadow: 2px 4px 5px 1px #cccccc; */
  font-size: 14px;
  border-radius: 6px;
  margin-bottom: 6px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  width: 100%;
}
.nameHeading h4 {
  font-size: 25px;
  font-weight: 600;
  color: #273a44;
  margin-bottom: 10px;
}
.nameHeading h5 {
  font-size: 12px;
  font-weight: 600;
  color: #273a44;
}
.nameHeading p {
  font-size: 12px;
  font-weight: 400;
  color: #273a44;
  margin-bottom: 9px;
}
.emo_root {
  display: flex;
}
.FeedbackMain {
  width: 90%;
  text-align: center;
  margin: 0 auto;
}
.top_vehicle_numMain {
  width: 50%;
  margin: 12px auto;
}
.feedBackEmogi {
  text-align: left;
  margin-bottom: 42px;
  margin-top: 35px;
}
.feedBackEmogi h3 {
  font-size: 14px;
  font-weight: 600;
  color: #273a44;
}
.fooerLogo {
  margin: 40px 0;
  text-align: center;
}
body {
  background-color: #fff !important;
}
.radioGroup {
  width: 20%;
  color: #b0b0b0;
}
.radioGroup span {
  font-size: 12px !important;
}
.radioGroup label {
  margin: 0;
}
.comments {
  text-align: left;
  margin-bottom: 45px;
}
.comments h3 {
  font-size: 14px;
  font-weight: 600;
  color: #273a44;
}
.comments p svg {
  margin-right: 10px;
}
.comments p {
  font-size: 12px;
  font-weight: 400;
  color: #b1b1b1;
  margin: 8px 0;
}
.feedbackComponentRoot {
  display: flex;
}
.logo {
  margin: 25px 0px;
}
@media screen and (min-width: 768px) {
  .FeedbackMain {
    width: 50%;
  }
}
