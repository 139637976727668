.addProducteBtn {
  padding: 8px 12px;
  height: 37px;
  /* width: 130px !important; */
  margin-left: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 12px !important;
  background: #0761e9;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.inventoryNavBar {
  display: flex !important;
  justify-content: space-between !important;
  padding: 15px !important;
  background-color: white;
}
.inventorySearch {
  display: flex !important;
}
.filterButton {
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  box-shadow: none !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #2f333b !important;
  float: inline-end;
  margin-left: 20px !important;
}
/* spare part css */
.spares_wrapper {
  width: 142.5px;
  height: 198px;
  padding: 24px 14px;
  background: #ffffff;
  border: 1px solid #f0f1ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 20px; */
}
.spares_name {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  margin: 0;
  padding-bottom: 4px;
  text-align: center;
  text-transform: capitalize;

  color: #2f333b;
}
.spares_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 4px;
  color: #2f333b;
}
.spares_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #388e55;
}
.spares_out_stock {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  color: #e22d2d;
}
.edit_item_botton_spares {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: center;
  background: #ffffff;
  margin: 0;
  padding: 0;
}
.subHeading {
  font-family: "Inter";
  color: #2f333b;
  font-weight: 600;
  font-size: 12px;
  margin-left: 10px;
}
/* product inventory form css */
.pagrHeadingContainer {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-bottom: 3px solid #f5f5f5;
}
.pageHeadig {
 color: #000000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    padding-left: 20px;
    margin: 0;
}
.mainPanel {
  display: flex;
  margin-left: 20px;
  padding-top: 30px !important;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  margin-top: 30px !important;
}
.rightPanel {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 70px;
  /* justify-content: center; */
  /* margin-top: -120px !important; */
}
.addImageSection {
  position: relative;
  top: -70px;
  width: 80%;
}
.label {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  color: #2f333b;
  margin-bottom: 8px;
}
.formControl {
  margin-bottom: 15px !important;
}
.img_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 99px;
  height: 101px;
  background: #f8f8f8;
}
.uploadLogo_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.add_img_txt {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: #0761e9;
}
.pageFooterContainer {
  position: absolute;
  bottom: 45px !important;
  right: 0px !important;
  display: flex;
  width: 100%;
  /* background-color: white; */
  justify-content: flex-end;
  padding: 21px;
}
.backBtn {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  width: 53px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  text-transform: capitalize !important;
  color: #717171 !important;
  background: #ffffff !important;
  border: 1px solid #e7e7e7 !important;
  margin-right: 20px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}
.saveBtn {
  padding: 12px 12px 13px !important;
  gap: 9px !important;
  width: 121px !important;
  height: 40px !important;
  padding: 12px 12px 13px !important;
  background: #0761e9 !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: capitalize !important;
  color: #ffffff !important;
  margin-right: 24px !important;
    box-shadow: none !important;
  border-radius: 0px !important;
}
.editBtn {
  font-size: 12px;
  /* width: 22px;
    height: 15px; */
}

.root_contaer_box{
  background: #f7f7fc;
  height: 100%;
}

.outline_inputbox{
  background-color: white;
  border-radius: 0px !important;
}
