.stepper_wrapper {
  /* margin-top: 1px; */
  padding: 10px 0px !important;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
}
.css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd;
  border-top-style: solid;
  border-top-width: 4px;
}
.stick {
  height: 2px;
  width: 200px;
  background-color: #2f333b;
}

.step_label {
  font-family: Inter;
  font-weight: 700 !important;
  font-size: 8px !important;
  text-transform: uppercase !important;
  color: #2f333b !important;
}
.SteperLabel {
  color: #273a44 !important;
  font-size: 10px !important;
  font-family: Inter !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  word-wrap: break-word !important;
}
.selected_services_card {
  margin-left: 0px;
  margin-right: 0px;
}
@media screen and (min-width: 360px) {
}
@media screen and (min-width: 600px) {
}
@media screen and (min-width: 768px) {
  .SteperLabel {
    font-size: 16px;
  }
}
@media screen and (min-width: 991px) {
  /* .stepper_wrapper {
    margin: 0;
    padding: 19px 86px 20px 86px !important;
  } */
}
@media screen and (min-width: 1200px) {
}

.warper {
  border-left: "1px red solid";
}
