.title_list_items {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  /* border-bottom: 1px solid #dfdfdf;  */
  align-items: center;
  cursor: pointer;
  background-color: #ffffff;
}
.title_list_items_active {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  /* border-bottom: 1px solid #dfdfdf;  */
  align-items: center;
  cursor: pointer;
  background-color: #e9f2ff;
}
.title_list_items_date {
  display: flex;
}
.list_items_date_date {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #273a44;
  margin: 0px;
  position: relative;
  left: 5px;
  top: 5px;
}
.list_items_jobId {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  text-transform: capitalize;
  color: #273a44;
  margin: 0px;
}
.list_items_jobId_num {
  font-weight: 600;
}
