.selected_services {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-left: 17px !important;
    margin-right: 17px !important; */
  background: #f0f1ff;
  border: 1px solid #f0f1ff;
  margin: 0;
  /* margin-bottom: 4px; */
  padding: 10px 14px 0 6px;
}
.left_img {
  display: flex;
}
.service_name {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  padding-bottom: 7px;
  text-transform: capitalize;
  color: #2f333b;
}
.service_cost {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 7px;
  color: #2f333b;
}
.etd_time {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: #2f333b;
}
.img_wrapper {
  width: 60px;
  height: 60px;
  background: #f1f1ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 37px 22px; */
  margin-bottom: 10px;
}
.img_wrapper_img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  /* padding: 37px 22px; */
  /* margin-bottom: 10px; */
}
